import styled from "styled-components";

const VerticalRule = styled.hr`
  width: 80%;
  border: none;
  border-top: 2px solid ${(props) => (props.color ? props.color : "#e9e9e9")};
  margin: auto;
`;

export default VerticalRule;
