import React from "react";
import styled from "styled-components";

const CenteredSection = styled.section`
  width: ${(props) => (props.full ? "100%" : "80%")};
  margin: ${(props) => (props.full ? "auto" : "5rem auto 5rem auto")};
  padding: ${(props) => (props.full ? "0 10% 0 10%" : "0")};
`;

const divideHeadings = (headingText) => {
  return headingText.split("/n").map((item, i) => {
    return <p key={i}>{item}</p>;
  });
};

const Section = (props) => {
  return (
    <CenteredSection
      id={`section-${props.klass}`}
      className={`section-${props.klass}`}
      full={props.full}
    >
      <div className="heading">
        <h2 className="heading__secondary">{props.title}</h2>
        <h3 className="heading__tertiary">{divideHeadings(props.heading)}</h3>
      </div>
      {props.children}
    </CenteredSection>
  );
};

export default Section;
