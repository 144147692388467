import styled from "styled-components";
import logo from "./img/logo.png";

const LogoImage = styled.div`
  background: no-repeat url("${logo}");
  width: 10rem;
  height: 100%;
  background-size: contain;
  background-position: left;
`;

export default LogoImage;
