import styled from "styled-components";

const Footer = styled.footer`
  width: 100%;
  margin: auto;
  background: #e9e9e9;
  height: 50px;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: 0.05em;
`;

export default Footer;
