const base = "https://s3.eu-central-1.amazonaws.com/kristinkaamichal.sk/";
const webDirectory = "web/";
const thumbnailDirectory = "thumb/";

const prepareImages = (picture) => {
  const basePicture = {
    src: `${base}${webDirectory}${picture.base}`,
    thumbnail: `${base}${thumbnailDirectory}${picture.base}`,
    ...picture,
  };
  if (picture.portait) {
    basePicture["thumbnailWidth"] = 246;
    basePicture["thumbnailHeight"] = 368;
  } else {
    basePicture["thumbnailWidth"] = 368;
    basePicture["thumbnailHeight"] = 246;
  }

  return basePicture;
};

export const imageBatch1 = [
  { base: "01_Pripravy/K&Mwedd_prip001.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip002.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip003.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip004.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip005.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip006.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip007.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip008.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip009.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip010.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip011.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip012.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip013.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip015.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip016.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip017.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip019.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip021.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip022.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip023.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip025.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip026.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip027.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip028.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip029.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip033.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip034.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip035.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip036.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip037.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip038.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip039.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip040.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip042.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip044.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip046.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip047.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip048.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip051.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip052.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip054.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip055.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip057.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip059.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip060.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip061.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip064.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip065.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip066.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip067.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip068.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip070.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip071.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip072.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip073.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip074.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip076.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip077.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip078.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip079.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip080.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip081.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip085.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip086.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip087.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip088.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip089.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip090.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip091.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip092.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip093.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip094.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip095.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip096.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip097.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip098.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip099.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip100.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip101.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip102.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip103.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip104.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip105.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip106.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip107.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip108.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip109.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip110.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip111.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip112.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip113.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip114.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip115.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip116.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip117.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip118.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip119.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip120.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip122.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip123.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip124.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip127.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip128.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip129.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip130.jpg", portait: true },
  { base: "01_Pripravy/K&Mwedd_prip131.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip132.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip134.jpg" },
  { base: "01_Pripravy/K&Mwedd_prip136.jpg" },
].map((picture) => prepareImages(picture));

export const imageBatch2 = [
  { base: "02_Portrety/K&Mwedd_port001.jpg" },
  { base: "02_Portrety/K&Mwedd_port002.jpg" },
  { base: "02_Portrety/K&Mwedd_port003.jpg" },
  { base: "02_Portrety/K&Mwedd_port004.jpg" },
  { base: "02_Portrety/K&Mwedd_port005.jpg" },
  { base: "02_Portrety/K&Mwedd_port007.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port010.jpg" },
  { base: "02_Portrety/K&Mwedd_port012.jpg" },
  { base: "02_Portrety/K&Mwedd_port013.jpg" },
  { base: "02_Portrety/K&Mwedd_port014.jpg" },
  { base: "02_Portrety/K&Mwedd_port015.jpg" },
  { base: "02_Portrety/K&Mwedd_port016.jpg" },
  { base: "02_Portrety/K&Mwedd_port017.jpg" },
  { base: "02_Portrety/K&Mwedd_port018.jpg" },
  { base: "02_Portrety/K&Mwedd_port019.jpg" },
  { base: "02_Portrety/K&Mwedd_port020.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port021.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port022.jpg" },
  { base: "02_Portrety/K&Mwedd_port023.jpg" },
  { base: "02_Portrety/K&Mwedd_port024.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port025.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port026.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port027.jpg" },
  { base: "02_Portrety/K&Mwedd_port028.jpg" },
  { base: "02_Portrety/K&Mwedd_port029.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port030.jpg" },
  { base: "02_Portrety/K&Mwedd_port031.jpg" },
  { base: "02_Portrety/K&Mwedd_port032.jpg" },
  { base: "02_Portrety/K&Mwedd_port034.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port035.jpg" },
  { base: "02_Portrety/K&Mwedd_port036.jpg" },
  { base: "02_Portrety/K&Mwedd_port037.jpg" },
  { base: "02_Portrety/K&Mwedd_port038.jpg" },
  { base: "02_Portrety/K&Mwedd_port039.jpg" },
  { base: "02_Portrety/K&Mwedd_port040.jpg" },
  { base: "02_Portrety/K&Mwedd_port041.jpg" },
  { base: "02_Portrety/K&Mwedd_port042.jpg" },
  { base: "02_Portrety/K&Mwedd_port043.jpg" },
  { base: "02_Portrety/K&Mwedd_port044.jpg" },
  { base: "02_Portrety/K&Mwedd_port045.jpg" },
  { base: "02_Portrety/K&Mwedd_port046.jpg" },
  { base: "02_Portrety/K&Mwedd_port048.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port049.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port050.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port051.jpg" },
  { base: "02_Portrety/K&Mwedd_port052.jpg" },
  { base: "02_Portrety/K&Mwedd_port054.jpg" },
  { base: "02_Portrety/K&Mwedd_port055.jpg" },
  { base: "02_Portrety/K&Mwedd_port056.jpg" },
  { base: "02_Portrety/K&Mwedd_port057.jpg" },
  { base: "02_Portrety/K&Mwedd_port058.jpg" },
  { base: "02_Portrety/K&Mwedd_port059.jpg" },
  { base: "02_Portrety/K&Mwedd_port060.jpg" },
  { base: "02_Portrety/K&Mwedd_port061.jpg" },
  { base: "02_Portrety/K&Mwedd_port062.jpg" },
  { base: "02_Portrety/K&Mwedd_port064.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port065.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port066.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port067.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port069.jpg" },
  { base: "02_Portrety/K&Mwedd_port070.jpg" },
  { base: "02_Portrety/K&Mwedd_port071.jpg" },
  { base: "02_Portrety/K&Mwedd_port072.jpg" },
  { base: "02_Portrety/K&Mwedd_port073.jpg" },
  { base: "02_Portrety/K&Mwedd_port074.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port076.jpg" },
  { base: "02_Portrety/K&Mwedd_port077.jpg" },
  { base: "02_Portrety/K&Mwedd_port079.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port080.jpg" },
  { base: "02_Portrety/K&Mwedd_port081.jpg" },
  { base: "02_Portrety/K&Mwedd_port082.jpg" },
  { base: "02_Portrety/K&Mwedd_port083.jpg" },
  { base: "02_Portrety/K&Mwedd_port084.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port086.jpg" },
  { base: "02_Portrety/K&Mwedd_port087.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port088.jpg" },
  { base: "02_Portrety/K&Mwedd_port089.jpg" },
  { base: "02_Portrety/K&Mwedd_port090.jpg" },
  { base: "02_Portrety/K&Mwedd_port091.jpg" },
  { base: "02_Portrety/K&Mwedd_port094.jpg" },
  { base: "02_Portrety/K&Mwedd_port095.jpg" },
  { base: "02_Portrety/K&Mwedd_port096.jpg" },
  { base: "02_Portrety/K&Mwedd_port097.jpg" },
  { base: "02_Portrety/K&Mwedd_port098.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port099.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port100.jpg" },
  { base: "02_Portrety/K&Mwedd_port101.jpg" },
  { base: "02_Portrety/K&Mwedd_port102.jpg" },
  { base: "02_Portrety/K&Mwedd_port103.jpg" },
  { base: "02_Portrety/K&Mwedd_port104.jpg" },
  { base: "02_Portrety/K&Mwedd_port105.jpg" },
  { base: "02_Portrety/K&Mwedd_port106.jpg" },
  { base: "02_Portrety/K&Mwedd_port107.jpg" },
  { base: "02_Portrety/K&Mwedd_port108.jpg" },
  { base: "02_Portrety/K&Mwedd_port109.jpg" },
  { base: "02_Portrety/K&Mwedd_port110.jpg" },
  { base: "02_Portrety/K&Mwedd_port111.jpg" },
  { base: "02_Portrety/K&Mwedd_port112.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port113.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port114.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port115.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port117.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port118.jpg" },
  { base: "02_Portrety/K&Mwedd_port119.jpg" },
  { base: "02_Portrety/K&Mwedd_port120.jpg" },
  { base: "02_Portrety/K&Mwedd_port121.jpg" },
  { base: "02_Portrety/K&Mwedd_port122.jpg" },
  { base: "02_Portrety/K&Mwedd_port123.jpg" },
  { base: "02_Portrety/K&Mwedd_port125.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port126.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port127.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port128.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port129.jpg" },
  { base: "02_Portrety/K&Mwedd_port130.jpg" },
  { base: "02_Portrety/K&Mwedd_port133.jpg" },
  { base: "02_Portrety/K&Mwedd_port135.jpg" },
  { base: "02_Portrety/K&Mwedd_port136.jpg" },
  { base: "02_Portrety/K&Mwedd_port137.jpg" },
  { base: "02_Portrety/K&Mwedd_port138.jpg" },
  { base: "02_Portrety/K&Mwedd_port139.jpg" },
  { base: "02_Portrety/K&Mwedd_port140.jpg" },
  { base: "02_Portrety/K&Mwedd_port141.jpg" },
  { base: "02_Portrety/K&Mwedd_port142.jpg" },
  { base: "02_Portrety/K&Mwedd_port143.jpg" },
  { base: "02_Portrety/K&Mwedd_port144.jpg" },
  { base: "02_Portrety/K&Mwedd_port145.jpg" },
  { base: "02_Portrety/K&Mwedd_port146.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port147.jpg" },
  { base: "02_Portrety/K&Mwedd_port148.jpg" },
  { base: "02_Portrety/K&Mwedd_port149.jpg" },
  { base: "02_Portrety/K&Mwedd_port151.jpg" },
  { base: "02_Portrety/K&Mwedd_port152.jpg" },
  { base: "02_Portrety/K&Mwedd_port153.jpg" },
  { base: "02_Portrety/K&Mwedd_port154.jpg" },
  { base: "02_Portrety/K&Mwedd_port156.jpg" },
  { base: "02_Portrety/K&Mwedd_port158.jpg" },
  { base: "02_Portrety/K&Mwedd_port159.jpg" },
  { base: "02_Portrety/K&Mwedd_port160.jpg" },
  { base: "02_Portrety/K&Mwedd_port161.jpg" },
  { base: "02_Portrety/K&Mwedd_port162.jpg" },
  { base: "02_Portrety/K&Mwedd_port163.jpg" },
  { base: "02_Portrety/K&Mwedd_port164.jpg" },
  { base: "02_Portrety/K&Mwedd_port165.jpg" },
  { base: "02_Portrety/K&Mwedd_port166.jpg" },
  { base: "02_Portrety/K&Mwedd_port167.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port168.jpg", portait: true },
  { base: "02_Portrety/K&Mwedd_port169.jpg" },
  { base: "02_Portrety/K&Mwedd_port170.jpg" },
  { base: "02_Portrety/K&Mwedd_port171.jpg" },
  { base: "02_Portrety/K&Mwedd_port173.jpg" },
  { base: "02_Portrety/K&Mwedd_port175.jpg" },
].map((picture) => prepareImages(picture));

export const imageBatch3 = [
  { base: "03_Obrad/K&Mwedd_obr001.jpg" },
  { base: "03_Obrad/K&Mwedd_obr002.jpg", portait: true },
  { base: "03_Obrad/K&Mwedd_obr003.jpg" },
  { base: "03_Obrad/K&Mwedd_obr004.jpg" },
  { base: "03_Obrad/K&Mwedd_obr005.jpg" },
  { base: "03_Obrad/K&Mwedd_obr006.jpg" },
  { base: "03_Obrad/K&Mwedd_obr007.jpg" },
  { base: "03_Obrad/K&Mwedd_obr008.jpg" },
  { base: "03_Obrad/K&Mwedd_obr010.jpg" },
  { base: "03_Obrad/K&Mwedd_obr011.jpg" },
  { base: "03_Obrad/K&Mwedd_obr012.jpg" },
  { base: "03_Obrad/K&Mwedd_obr013.jpg" },
  { base: "03_Obrad/K&Mwedd_obr014.jpg" },
  { base: "03_Obrad/K&Mwedd_obr015.jpg" },
  { base: "03_Obrad/K&Mwedd_obr016.jpg" },
  { base: "03_Obrad/K&Mwedd_obr017.jpg" },
  { base: "03_Obrad/K&Mwedd_obr018.jpg" },
  { base: "03_Obrad/K&Mwedd_obr019.jpg" },
  { base: "03_Obrad/K&Mwedd_obr020.jpg" },
  { base: "03_Obrad/K&Mwedd_obr021.jpg" },
  { base: "03_Obrad/K&Mwedd_obr022.jpg" },
  { base: "03_Obrad/K&Mwedd_obr023.jpg" },
  { base: "03_Obrad/K&Mwedd_obr024.jpg" },
  { base: "03_Obrad/K&Mwedd_obr025.jpg" },
  { base: "03_Obrad/K&Mwedd_obr026.jpg" },
  { base: "03_Obrad/K&Mwedd_obr027.jpg" },
  { base: "03_Obrad/K&Mwedd_obr028.jpg" },
  { base: "03_Obrad/K&Mwedd_obr029.jpg" },
  { base: "03_Obrad/K&Mwedd_obr030.jpg" },
  { base: "03_Obrad/K&Mwedd_obr031.jpg" },
  { base: "03_Obrad/K&Mwedd_obr032.jpg" },
  { base: "03_Obrad/K&Mwedd_obr033.jpg" },
  { base: "03_Obrad/K&Mwedd_obr034.jpg" },
  { base: "03_Obrad/K&Mwedd_obr035.jpg" },
  { base: "03_Obrad/K&Mwedd_obr036.jpg" },
  { base: "03_Obrad/K&Mwedd_obr037.jpg" },
  { base: "03_Obrad/K&Mwedd_obr038.jpg" },
  { base: "03_Obrad/K&Mwedd_obr039.jpg" },
  { base: "03_Obrad/K&Mwedd_obr040.jpg" },
  { base: "03_Obrad/K&Mwedd_obr041.jpg" },
  { base: "03_Obrad/K&Mwedd_obr042.jpg" },
  { base: "03_Obrad/K&Mwedd_obr043.jpg" },
  { base: "03_Obrad/K&Mwedd_obr044.jpg" },
  { base: "03_Obrad/K&Mwedd_obr045.jpg" },
  { base: "03_Obrad/K&Mwedd_obr046.jpg" },
  { base: "03_Obrad/K&Mwedd_obr047.jpg" },
  { base: "03_Obrad/K&Mwedd_obr048.jpg" },
  { base: "03_Obrad/K&Mwedd_obr049.jpg" },
  { base: "03_Obrad/K&Mwedd_obr050.jpg" },
  { base: "03_Obrad/K&Mwedd_obr051.jpg" },
  { base: "03_Obrad/K&Mwedd_obr052.jpg" },
  { base: "03_Obrad/K&Mwedd_obr053.jpg" },
  { base: "03_Obrad/K&Mwedd_obr054.jpg" },
  { base: "03_Obrad/K&Mwedd_obr055.jpg" },
  { base: "03_Obrad/K&Mwedd_obr056.jpg" },
  { base: "03_Obrad/K&Mwedd_obr057.jpg" },
  { base: "03_Obrad/K&Mwedd_obr058.jpg" },
  { base: "03_Obrad/K&Mwedd_obr059.jpg" },
  { base: "03_Obrad/K&Mwedd_obr060.jpg" },
  { base: "03_Obrad/K&Mwedd_obr061.jpg" },
  { base: "03_Obrad/K&Mwedd_obr062.jpg" },
  { base: "03_Obrad/K&Mwedd_obr063.jpg" },
  { base: "03_Obrad/K&Mwedd_obr064.jpg" },
  { base: "03_Obrad/K&Mwedd_obr065.jpg" },
  { base: "03_Obrad/K&Mwedd_obr066.jpg" },
  { base: "03_Obrad/K&Mwedd_obr067.jpg" },
  { base: "03_Obrad/K&Mwedd_obr068.jpg" },
  { base: "03_Obrad/K&Mwedd_obr069.jpg" },
  { base: "03_Obrad/K&Mwedd_obr070.jpg" },
  { base: "03_Obrad/K&Mwedd_obr071.jpg" },
  { base: "03_Obrad/K&Mwedd_obr072.jpg" },
  { base: "03_Obrad/K&Mwedd_obr073.jpg" },
  { base: "03_Obrad/K&Mwedd_obr074.jpg" },
  { base: "03_Obrad/K&Mwedd_obr075.jpg" },
  { base: "03_Obrad/K&Mwedd_obr076.jpg" },
  { base: "03_Obrad/K&Mwedd_obr077.jpg" },
  { base: "03_Obrad/K&Mwedd_obr078.jpg" },
  { base: "03_Obrad/K&Mwedd_obr079.jpg" },
  { base: "03_Obrad/K&Mwedd_obr080.jpg" },
  { base: "03_Obrad/K&Mwedd_obr081.jpg" },
  { base: "03_Obrad/K&Mwedd_obr082.jpg" },
  { base: "03_Obrad/K&Mwedd_obr083.jpg" },
  { base: "03_Obrad/K&Mwedd_obr084.jpg" },
  { base: "03_Obrad/K&Mwedd_obr085.jpg" },
  { base: "03_Obrad/K&Mwedd_obr086.jpg" },
  { base: "03_Obrad/K&Mwedd_obr087.jpg" },
].map((picture) => prepareImages(picture));

export const imageBatch4 = [
  { base: "04_Gratulacie/K&Mwedd_grat001.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat002.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat003.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat004.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat005.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat006.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat007.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat008.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat009.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat010.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat011.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat012.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat013.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat014.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat015.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat016.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat017.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat018.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat019.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat020.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat021.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat022.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat023.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat024.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat025.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat026.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat027.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat028.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat029.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat030.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat031.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat032.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat033.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat034.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat035.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat036.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat037.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat038.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat039.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat040.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat041.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat042.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat043.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat044.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat045.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat046.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat047.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat048.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat049.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat050.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat051.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat052.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat053.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat054.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat055.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat056.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat057.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat058.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat059.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat060.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat061.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat062.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat063.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat064.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat065.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat066.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat067.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat068.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat069.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat070.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat071.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat072.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat073.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat074.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat075.jpg" },
  { base: "04_Gratulacie/K&Mwedd_grat076.jpg" },
].map((picture) => prepareImages(picture));

export const imageBatch5 = [
  { base: "05_Skupinky/K&Mwedd_skup001.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup002.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup003.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup005.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup006.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup007.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup008.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup009.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup010.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup011.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup012.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup013.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup014.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup015.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup016.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup017.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup018.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup019.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup020.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup021.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup022.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup023.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup024.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup025.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup026.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup027.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup028.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup029.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup030.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup031.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup032.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup033.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup034.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup035.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup036.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup037.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup038.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup039.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup040.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup041.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup042.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup043.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup044.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup045.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup046.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup047.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup048.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup049.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup050.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup051.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup052.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup053.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup054.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup055.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup056.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup057.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup058.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup059.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup060.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup061.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup062.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup063.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup064.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup065.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup066.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup067.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup068.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup069.jpg" },
  { base: "05_Skupinky/K&Mwedd_skup070.jpg" },
].map((picture) => prepareImages(picture));

export const imageBatch6 = [
  { base: "06_Hostina/K&Mwedd_host001.jpg" },
  { base: "06_Hostina/K&Mwedd_host002.jpg" },
  { base: "06_Hostina/K&Mwedd_host003.jpg" },
  { base: "06_Hostina/K&Mwedd_host004.jpg" },
  { base: "06_Hostina/K&Mwedd_host005.jpg" },
  { base: "06_Hostina/K&Mwedd_host006.jpg" },
  { base: "06_Hostina/K&Mwedd_host007.jpg" },
  { base: "06_Hostina/K&Mwedd_host008.jpg" },
  { base: "06_Hostina/K&Mwedd_host009.jpg" },
  { base: "06_Hostina/K&Mwedd_host010.jpg" },
  { base: "06_Hostina/K&Mwedd_host011.jpg" },
  { base: "06_Hostina/K&Mwedd_host012.jpg" },
  { base: "06_Hostina/K&Mwedd_host013.jpg" },
  { base: "06_Hostina/K&Mwedd_host014.jpg" },
  { base: "06_Hostina/K&Mwedd_host015.jpg" },
  { base: "06_Hostina/K&Mwedd_host016.jpg" },
  { base: "06_Hostina/K&Mwedd_host017.jpg" },
  { base: "06_Hostina/K&Mwedd_host018.jpg" },
  { base: "06_Hostina/K&Mwedd_host019.jpg" },
  { base: "06_Hostina/K&Mwedd_host020.jpg" },
  { base: "06_Hostina/K&Mwedd_host021.jpg" },
  { base: "06_Hostina/K&Mwedd_host022.jpg" },
  { base: "06_Hostina/K&Mwedd_host023.jpg" },
  { base: "06_Hostina/K&Mwedd_host024.jpg" },
  { base: "06_Hostina/K&Mwedd_host025.jpg" },
  { base: "06_Hostina/K&Mwedd_host026.jpg" },
  { base: "06_Hostina/K&Mwedd_host027.jpg" },
  { base: "06_Hostina/K&Mwedd_host028.jpg" },
  { base: "06_Hostina/K&Mwedd_host029.jpg" },
  { base: "06_Hostina/K&Mwedd_host030.jpg" },
  { base: "06_Hostina/K&Mwedd_host031.jpg" },
  { base: "06_Hostina/K&Mwedd_host032.jpg" },
  { base: "06_Hostina/K&Mwedd_host033.jpg" },
  { base: "06_Hostina/K&Mwedd_host034.jpg" },
  { base: "06_Hostina/K&Mwedd_host035.jpg" },
  { base: "06_Hostina/K&Mwedd_host036.jpg" },
  { base: "06_Hostina/K&Mwedd_host037.jpg" },
  { base: "06_Hostina/K&Mwedd_host038.jpg" },
  { base: "06_Hostina/K&Mwedd_host039.jpg" },
  { base: "06_Hostina/K&Mwedd_host040.jpg" },
  { base: "06_Hostina/K&Mwedd_host041.jpg" },
  { base: "06_Hostina/K&Mwedd_host042.jpg" },
  { base: "06_Hostina/K&Mwedd_host043.jpg" },
  { base: "06_Hostina/K&Mwedd_host044.jpg" },
  { base: "06_Hostina/K&Mwedd_host045.jpg" },
  { base: "06_Hostina/K&Mwedd_host046.jpg" },
  { base: "06_Hostina/K&Mwedd_host047.jpg" },
  { base: "06_Hostina/K&Mwedd_host048.jpg" },
  { base: "06_Hostina/K&Mwedd_host049.jpg" },
  { base: "06_Hostina/K&Mwedd_host050.jpg" },
  { base: "06_Hostina/K&Mwedd_host051.jpg" },
  { base: "06_Hostina/K&Mwedd_host052.jpg" },
  { base: "06_Hostina/K&Mwedd_host053.jpg" },
  { base: "06_Hostina/K&Mwedd_host054.jpg" },
  { base: "06_Hostina/K&Mwedd_host055.jpg" },
  { base: "06_Hostina/K&Mwedd_host056.jpg" },
  { base: "06_Hostina/K&Mwedd_host057.jpg" },
  { base: "06_Hostina/K&Mwedd_host058.jpg" },
  { base: "06_Hostina/K&Mwedd_host059.jpg" },
  { base: "06_Hostina/K&Mwedd_host060.jpg" },
  { base: "06_Hostina/K&Mwedd_host061.jpg" },
  { base: "06_Hostina/K&Mwedd_host062.jpg" },
  { base: "06_Hostina/K&Mwedd_host063.jpg" },
  { base: "06_Hostina/K&Mwedd_host064.jpg" },
  { base: "06_Hostina/K&Mwedd_host065.jpg" },
  { base: "06_Hostina/K&Mwedd_host066.jpg" },
  { base: "06_Hostina/K&Mwedd_host067.jpg" },
  { base: "06_Hostina/K&Mwedd_host068.jpg" },
  { base: "06_Hostina/K&Mwedd_host069.jpg" },
  { base: "06_Hostina/K&Mwedd_host070.jpg" },
  { base: "06_Hostina/K&Mwedd_host071.jpg" },
  { base: "06_Hostina/K&Mwedd_host072.jpg" },
  { base: "06_Hostina/K&Mwedd_host073.jpg" },
  { base: "06_Hostina/K&Mwedd_host074.jpg" },
  { base: "06_Hostina/K&Mwedd_host075.jpg" },
  { base: "06_Hostina/K&Mwedd_host076.jpg" },
  { base: "06_Hostina/K&Mwedd_host077.jpg" },
  { base: "06_Hostina/K&Mwedd_host078.jpg" },
  { base: "06_Hostina/K&Mwedd_host079.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host080.jpg" },
  { base: "06_Hostina/K&Mwedd_host081.jpg" },
  { base: "06_Hostina/K&Mwedd_host082.jpg" },
  { base: "06_Hostina/K&Mwedd_host083.jpg" },
  { base: "06_Hostina/K&Mwedd_host084.jpg" },
  { base: "06_Hostina/K&Mwedd_host085.jpg" },
  { base: "06_Hostina/K&Mwedd_host086.jpg" },
  { base: "06_Hostina/K&Mwedd_host087.jpg" },
  { base: "06_Hostina/K&Mwedd_host088.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host089.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host090.jpg" },
  { base: "06_Hostina/K&Mwedd_host092.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host093.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host094.jpg" },
  { base: "06_Hostina/K&Mwedd_host095.jpg" },
  { base: "06_Hostina/K&Mwedd_host096.jpg" },
  { base: "06_Hostina/K&Mwedd_host097.jpg" },
  { base: "06_Hostina/K&Mwedd_host098.jpg" },
  { base: "06_Hostina/K&Mwedd_host099.jpg" },
  { base: "06_Hostina/K&Mwedd_host100.jpg" },
  { base: "06_Hostina/K&Mwedd_host101.jpg" },
  { base: "06_Hostina/K&Mwedd_host102.jpg" },
  { base: "06_Hostina/K&Mwedd_host103.jpg" },
  { base: "06_Hostina/K&Mwedd_host104.jpg" },
  { base: "06_Hostina/K&Mwedd_host105.jpg" },
  { base: "06_Hostina/K&Mwedd_host106.jpg" },
  { base: "06_Hostina/K&Mwedd_host107.jpg" },
  { base: "06_Hostina/K&Mwedd_host108.jpg" },
  { base: "06_Hostina/K&Mwedd_host109.jpg" },
  { base: "06_Hostina/K&Mwedd_host110.jpg" },
  { base: "06_Hostina/K&Mwedd_host111.jpg" },
  { base: "06_Hostina/K&Mwedd_host112.jpg" },
  { base: "06_Hostina/K&Mwedd_host113.jpg" },
  { base: "06_Hostina/K&Mwedd_host114.jpg" },
  { base: "06_Hostina/K&Mwedd_host115.jpg" },
  { base: "06_Hostina/K&Mwedd_host116.jpg" },
  { base: "06_Hostina/K&Mwedd_host117.jpg" },
  { base: "06_Hostina/K&Mwedd_host118.jpg" },
  { base: "06_Hostina/K&Mwedd_host119.jpg" },
  { base: "06_Hostina/K&Mwedd_host120.jpg" },
  { base: "06_Hostina/K&Mwedd_host121.jpg" },
  { base: "06_Hostina/K&Mwedd_host122.jpg" },
  { base: "06_Hostina/K&Mwedd_host123.jpg" },
  { base: "06_Hostina/K&Mwedd_host124.jpg" },
  { base: "06_Hostina/K&Mwedd_host125.jpg" },
  { base: "06_Hostina/K&Mwedd_host126.jpg" },
  { base: "06_Hostina/K&Mwedd_host127.jpg" },
  { base: "06_Hostina/K&Mwedd_host128.jpg" },
  { base: "06_Hostina/K&Mwedd_host129.jpg" },
  { base: "06_Hostina/K&Mwedd_host130.jpg" },
  { base: "06_Hostina/K&Mwedd_host131.jpg" },
  { base: "06_Hostina/K&Mwedd_host132.jpg" },
  { base: "06_Hostina/K&Mwedd_host133.jpg" },
  { base: "06_Hostina/K&Mwedd_host134.jpg" },
  { base: "06_Hostina/K&Mwedd_host135.jpg" },
  { base: "06_Hostina/K&Mwedd_host136.jpg" },
  { base: "06_Hostina/K&Mwedd_host137.jpg" },
  { base: "06_Hostina/K&Mwedd_host138.jpg" },
  { base: "06_Hostina/K&Mwedd_host139.jpg" },
  { base: "06_Hostina/K&Mwedd_host140.jpg" },
  { base: "06_Hostina/K&Mwedd_host141.jpg" },
  { base: "06_Hostina/K&Mwedd_host142.jpg" },
  { base: "06_Hostina/K&Mwedd_host143.jpg" },
  { base: "06_Hostina/K&Mwedd_host144.jpg" },
  { base: "06_Hostina/K&Mwedd_host145.jpg" },
  { base: "06_Hostina/K&Mwedd_host146.jpg" },
  { base: "06_Hostina/K&Mwedd_host147.jpg" },
  { base: "06_Hostina/K&Mwedd_host148.jpg" },
  { base: "06_Hostina/K&Mwedd_host149.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host150.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host151.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host152.jpg" },
  { base: "06_Hostina/K&Mwedd_host153.jpg" },
  { base: "06_Hostina/K&Mwedd_host154.jpg" },
  { base: "06_Hostina/K&Mwedd_host155.jpg" },
  { base: "06_Hostina/K&Mwedd_host156.jpg" },
  { base: "06_Hostina/K&Mwedd_host157.jpg" },
  { base: "06_Hostina/K&Mwedd_host158.jpg" },
  { base: "06_Hostina/K&Mwedd_host159.jpg" },
  { base: "06_Hostina/K&Mwedd_host160.jpg" },
  { base: "06_Hostina/K&Mwedd_host161.jpg" },
  { base: "06_Hostina/K&Mwedd_host162.jpg" },
  { base: "06_Hostina/K&Mwedd_host163.jpg" },
  { base: "06_Hostina/K&Mwedd_host164.jpg" },
  { base: "06_Hostina/K&Mwedd_host165.jpg" },
  { base: "06_Hostina/K&Mwedd_host166.jpg" },
  { base: "06_Hostina/K&Mwedd_host167.jpg" },
  { base: "06_Hostina/K&Mwedd_host168.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host169.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host170.jpg" },
  { base: "06_Hostina/K&Mwedd_host171.jpg" },
  { base: "06_Hostina/K&Mwedd_host172.jpg" },
  { base: "06_Hostina/K&Mwedd_host173.jpg" },
  { base: "06_Hostina/K&Mwedd_host174.jpg" },
  { base: "06_Hostina/K&Mwedd_host175.jpg" },
  { base: "06_Hostina/K&Mwedd_host176.jpg" },
  { base: "06_Hostina/K&Mwedd_host177.jpg" },
  { base: "06_Hostina/K&Mwedd_host178.jpg" },
  { base: "06_Hostina/K&Mwedd_host179.jpg" },
  { base: "06_Hostina/K&Mwedd_host180.jpg" },
  { base: "06_Hostina/K&Mwedd_host181.jpg" },
  { base: "06_Hostina/K&Mwedd_host182.jpg" },
  { base: "06_Hostina/K&Mwedd_host183.jpg" },
  { base: "06_Hostina/K&Mwedd_host184.jpg" },
  { base: "06_Hostina/K&Mwedd_host185.jpg" },
  { base: "06_Hostina/K&Mwedd_host186.jpg" },
  { base: "06_Hostina/K&Mwedd_host187.jpg" },
  { base: "06_Hostina/K&Mwedd_host188.jpg" },
  { base: "06_Hostina/K&Mwedd_host189.jpg" },
  { base: "06_Hostina/K&Mwedd_host190.jpg" },
  { base: "06_Hostina/K&Mwedd_host191.jpg" },
  { base: "06_Hostina/K&Mwedd_host192.jpg" },
  { base: "06_Hostina/K&Mwedd_host193.jpg" },
  { base: "06_Hostina/K&Mwedd_host194.jpg" },
  { base: "06_Hostina/K&Mwedd_host195.jpg" },
  { base: "06_Hostina/K&Mwedd_host196.jpg" },
  { base: "06_Hostina/K&Mwedd_host197.jpg" },
  { base: "06_Hostina/K&Mwedd_host198.jpg" },
  { base: "06_Hostina/K&Mwedd_host199.jpg" },
  { base: "06_Hostina/K&Mwedd_host200.jpg" },
  { base: "06_Hostina/K&Mwedd_host201.jpg" },
  { base: "06_Hostina/K&Mwedd_host202.jpg" },
  { base: "06_Hostina/K&Mwedd_host203.jpg" },
  { base: "06_Hostina/K&Mwedd_host204.jpg" },
  { base: "06_Hostina/K&Mwedd_host205.jpg" },
  { base: "06_Hostina/K&Mwedd_host206.jpg" },
  { base: "06_Hostina/K&Mwedd_host207.jpg" },
  { base: "06_Hostina/K&Mwedd_host208.jpg" },
  { base: "06_Hostina/K&Mwedd_host209.jpg" },
  { base: "06_Hostina/K&Mwedd_host210.jpg" },
  { base: "06_Hostina/K&Mwedd_host211.jpg" },
  { base: "06_Hostina/K&Mwedd_host212.jpg" },
  { base: "06_Hostina/K&Mwedd_host213.jpg" },
  { base: "06_Hostina/K&Mwedd_host214.jpg" },
  { base: "06_Hostina/K&Mwedd_host215.jpg" },
  { base: "06_Hostina/K&Mwedd_host216.jpg" },
  { base: "06_Hostina/K&Mwedd_host217.jpg" },
  { base: "06_Hostina/K&Mwedd_host218.jpg" },
  { base: "06_Hostina/K&Mwedd_host219.jpg" },
  { base: "06_Hostina/K&Mwedd_host220.jpg" },
  { base: "06_Hostina/K&Mwedd_host221.jpg" },
  { base: "06_Hostina/K&Mwedd_host222.jpg" },
  { base: "06_Hostina/K&Mwedd_host223.jpg" },
  { base: "06_Hostina/K&Mwedd_host224.jpg" },
  { base: "06_Hostina/K&Mwedd_host225.jpg" },
  { base: "06_Hostina/K&Mwedd_host226.jpg" },
  { base: "06_Hostina/K&Mwedd_host227.jpg" },
  { base: "06_Hostina/K&Mwedd_host228.jpg" },
  { base: "06_Hostina/K&Mwedd_host229.jpg" },
  { base: "06_Hostina/K&Mwedd_host230.jpg" },
  { base: "06_Hostina/K&Mwedd_host231.jpg" },
  { base: "06_Hostina/K&Mwedd_host232.jpg" },
  { base: "06_Hostina/K&Mwedd_host233.jpg" },
  { base: "06_Hostina/K&Mwedd_host234.jpg" },
  { base: "06_Hostina/K&Mwedd_host235.jpg" },
  { base: "06_Hostina/K&Mwedd_host244.jpg" },
  { base: "06_Hostina/K&Mwedd_host245.jpg" },
  { base: "06_Hostina/K&Mwedd_host246.jpg" },
  { base: "06_Hostina/K&Mwedd_host247.jpg" },
  { base: "06_Hostina/K&Mwedd_host248.jpg" },
  { base: "06_Hostina/K&Mwedd_host249.jpg" },
  { base: "06_Hostina/K&Mwedd_host250.jpg" },
  { base: "06_Hostina/K&Mwedd_host251.jpg" },
  { base: "06_Hostina/K&Mwedd_host252.jpg" },
  { base: "06_Hostina/K&Mwedd_host253.jpg" },
  { base: "06_Hostina/K&Mwedd_host254.jpg" },
  { base: "06_Hostina/K&Mwedd_host255.jpg" },
  { base: "06_Hostina/K&Mwedd_host256.jpg" },
  { base: "06_Hostina/K&Mwedd_host257.jpg" },
  { base: "06_Hostina/K&Mwedd_host258.jpg" },
  { base: "06_Hostina/K&Mwedd_host259.jpg" },
  { base: "06_Hostina/K&Mwedd_host260.jpg" },
  { base: "06_Hostina/K&Mwedd_host261.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host262.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host263.jpg" },
  { base: "06_Hostina/K&Mwedd_host264.jpg" },
  { base: "06_Hostina/K&Mwedd_host265.jpg" },
  { base: "06_Hostina/K&Mwedd_host266.jpg" },
  { base: "06_Hostina/K&Mwedd_host267.jpg" },
  { base: "06_Hostina/K&Mwedd_host268.jpg" },
  { base: "06_Hostina/K&Mwedd_host269.jpg" },
  { base: "06_Hostina/K&Mwedd_host270.jpg" },
  { base: "06_Hostina/K&Mwedd_host271.jpg" },
  { base: "06_Hostina/K&Mwedd_host272.jpg" },
  { base: "06_Hostina/K&Mwedd_host273.jpg" },
  { base: "06_Hostina/K&Mwedd_host274.jpg" },
  { base: "06_Hostina/K&Mwedd_host275.jpg" },
  { base: "06_Hostina/K&Mwedd_host276.jpg" },
  { base: "06_Hostina/K&Mwedd_host277.jpg" },
  { base: "06_Hostina/K&Mwedd_host278.jpg" },
  { base: "06_Hostina/K&Mwedd_host279.jpg" },
  { base: "06_Hostina/K&Mwedd_host280.jpg" },
  { base: "06_Hostina/K&Mwedd_host281.jpg" },
  { base: "06_Hostina/K&Mwedd_host282.jpg" },
  { base: "06_Hostina/K&Mwedd_host283.jpg" },
  { base: "06_Hostina/K&Mwedd_host284.jpg" },
  { base: "06_Hostina/K&Mwedd_host285.jpg" },
  { base: "06_Hostina/K&Mwedd_host286.jpg" },
  { base: "06_Hostina/K&Mwedd_host287.jpg" },
  { base: "06_Hostina/K&Mwedd_host288.jpg" },
  { base: "06_Hostina/K&Mwedd_host289.jpg" },
  { base: "06_Hostina/K&Mwedd_host290.jpg" },
  { base: "06_Hostina/K&Mwedd_host291.jpg" },
  { base: "06_Hostina/K&Mwedd_host292.jpg" },
  { base: "06_Hostina/K&Mwedd_host293.jpg" },
  { base: "06_Hostina/K&Mwedd_host294.jpg" },
  { base: "06_Hostina/K&Mwedd_host295.jpg" },
  { base: "06_Hostina/K&Mwedd_host296.jpg" },
  { base: "06_Hostina/K&Mwedd_host297.jpg" },
  { base: "06_Hostina/K&Mwedd_host298.jpg" },
  { base: "06_Hostina/K&Mwedd_host299.jpg" },
  { base: "06_Hostina/K&Mwedd_host300.jpg" },
  { base: "06_Hostina/K&Mwedd_host301.jpg" },
  { base: "06_Hostina/K&Mwedd_host302.jpg" },
  { base: "06_Hostina/K&Mwedd_host303.jpg" },
  { base: "06_Hostina/K&Mwedd_host304.jpg" },
  { base: "06_Hostina/K&Mwedd_host305.jpg" },
  { base: "06_Hostina/K&Mwedd_host306.jpg" },
  { base: "06_Hostina/K&Mwedd_host307.jpg" },
  { base: "06_Hostina/K&Mwedd_host308.jpg" },
  { base: "06_Hostina/K&Mwedd_host309.jpg" },
  { base: "06_Hostina/K&Mwedd_host310.jpg" },
  { base: "06_Hostina/K&Mwedd_host311.jpg" },
  { base: "06_Hostina/K&Mwedd_host312.jpg" },
  { base: "06_Hostina/K&Mwedd_host313.jpg" },
  { base: "06_Hostina/K&Mwedd_host314.jpg" },
  { base: "06_Hostina/K&Mwedd_host315.jpg" },
  { base: "06_Hostina/K&Mwedd_host316.jpg" },
  { base: "06_Hostina/K&Mwedd_host317.jpg" },
  { base: "06_Hostina/K&Mwedd_host318.jpg" },
  { base: "06_Hostina/K&Mwedd_host319.jpg" },
  { base: "06_Hostina/K&Mwedd_host320.jpg" },
  { base: "06_Hostina/K&Mwedd_host321.jpg" },
  { base: "06_Hostina/K&Mwedd_host322.jpg" },
  { base: "06_Hostina/K&Mwedd_host323.jpg" },
  { base: "06_Hostina/K&Mwedd_host324.jpg" },
  { base: "06_Hostina/K&Mwedd_host325.jpg" },
  { base: "06_Hostina/K&Mwedd_host326.jpg" },
  { base: "06_Hostina/K&Mwedd_host327.jpg" },
  { base: "06_Hostina/K&Mwedd_host328.jpg" },
  { base: "06_Hostina/K&Mwedd_host329.jpg" },
  { base: "06_Hostina/K&Mwedd_host330.jpg" },
  { base: "06_Hostina/K&Mwedd_host331.jpg" },
  { base: "06_Hostina/K&Mwedd_host332.jpg" },
  { base: "06_Hostina/K&Mwedd_host333.jpg" },
  { base: "06_Hostina/K&Mwedd_host334.jpg" },
  { base: "06_Hostina/K&Mwedd_host335.jpg" },
  { base: "06_Hostina/K&Mwedd_host336.jpg" },
  { base: "06_Hostina/K&Mwedd_host337.jpg" },
  { base: "06_Hostina/K&Mwedd_host338.jpg" },
  { base: "06_Hostina/K&Mwedd_host339.jpg" },
  { base: "06_Hostina/K&Mwedd_host340.jpg" },
  { base: "06_Hostina/K&Mwedd_host341.jpg" },
  { base: "06_Hostina/K&Mwedd_host342.jpg" },
  { base: "06_Hostina/K&Mwedd_host343.jpg" },
  { base: "06_Hostina/K&Mwedd_host344.jpg" },
  { base: "06_Hostina/K&Mwedd_host345.jpg" },
  { base: "06_Hostina/K&Mwedd_host346.jpg" },
  { base: "06_Hostina/K&Mwedd_host347.jpg" },
  { base: "06_Hostina/K&Mwedd_host348.jpg" },
  { base: "06_Hostina/K&Mwedd_host349.jpg" },
  { base: "06_Hostina/K&Mwedd_host350.jpg" },
  { base: "06_Hostina/K&Mwedd_host351.jpg" },
  { base: "06_Hostina/K&Mwedd_host352.jpg" },
  { base: "06_Hostina/K&Mwedd_host353.jpg" },
  { base: "06_Hostina/K&Mwedd_host354.jpg" },
  { base: "06_Hostina/K&Mwedd_host355.jpg" },
  { base: "06_Hostina/K&Mwedd_host356.jpg" },
  { base: "06_Hostina/K&Mwedd_host357.jpg" },
  { base: "06_Hostina/K&Mwedd_host358.jpg" },
  { base: "06_Hostina/K&Mwedd_host359.jpg" },
  { base: "06_Hostina/K&Mwedd_host360.jpg" },
  { base: "06_Hostina/K&Mwedd_host361.jpg" },
  { base: "06_Hostina/K&Mwedd_host362.jpg" },
  { base: "06_Hostina/K&Mwedd_host363.jpg" },
  { base: "06_Hostina/K&Mwedd_host364.jpg" },
  { base: "06_Hostina/K&Mwedd_host365.jpg" },
  { base: "06_Hostina/K&Mwedd_host366.jpg" },
  { base: "06_Hostina/K&Mwedd_host367.jpg" },
  { base: "06_Hostina/K&Mwedd_host368.jpg" },
  { base: "06_Hostina/K&Mwedd_host369.jpg" },
  { base: "06_Hostina/K&Mwedd_host370.jpg" },
  { base: "06_Hostina/K&Mwedd_host371.jpg" },
  { base: "06_Hostina/K&Mwedd_host372.jpg" },
  { base: "06_Hostina/K&Mwedd_host373.jpg" },
  { base: "06_Hostina/K&Mwedd_host374.jpg" },
  { base: "06_Hostina/K&Mwedd_host375.jpg" },
  { base: "06_Hostina/K&Mwedd_host376.jpg" },
  { base: "06_Hostina/K&Mwedd_host377.jpg" },
  { base: "06_Hostina/K&Mwedd_host378.jpg" },
  { base: "06_Hostina/K&Mwedd_host379.jpg" },
  { base: "06_Hostina/K&Mwedd_host380.jpg" },
  { base: "06_Hostina/K&Mwedd_host381.jpg" },
  { base: "06_Hostina/K&Mwedd_host382.jpg" },
  { base: "06_Hostina/K&Mwedd_host383.jpg" },
  { base: "06_Hostina/K&Mwedd_host384.jpg" },
  { base: "06_Hostina/K&Mwedd_host385.jpg" },
  { base: "06_Hostina/K&Mwedd_host386.jpg" },
  { base: "06_Hostina/K&Mwedd_host387.jpg" },
  { base: "06_Hostina/K&Mwedd_host388.jpg" },
  { base: "06_Hostina/K&Mwedd_host389.jpg" },
  { base: "06_Hostina/K&Mwedd_host390.jpg" },
  { base: "06_Hostina/K&Mwedd_host391.jpg" },
  { base: "06_Hostina/K&Mwedd_host392.jpg" },
  { base: "06_Hostina/K&Mwedd_host393.jpg" },
  { base: "06_Hostina/K&Mwedd_host394.jpg" },
  { base: "06_Hostina/K&Mwedd_host395.jpg" },
  { base: "06_Hostina/K&Mwedd_host396.jpg" },
  { base: "06_Hostina/K&Mwedd_host397.jpg" },
  { base: "06_Hostina/K&Mwedd_host398.jpg" },
  { base: "06_Hostina/K&Mwedd_host399.jpg" },
  { base: "06_Hostina/K&Mwedd_host400.jpg" },
  { base: "06_Hostina/K&Mwedd_host401.jpg" },
  { base: "06_Hostina/K&Mwedd_host402.jpg" },
  { base: "06_Hostina/K&Mwedd_host403.jpg" },
  { base: "06_Hostina/K&Mwedd_host404.jpg" },
  { base: "06_Hostina/K&Mwedd_host405.jpg" },
  { base: "06_Hostina/K&Mwedd_host406.jpg" },
  { base: "06_Hostina/K&Mwedd_host407.jpg" },
  { base: "06_Hostina/K&Mwedd_host408.jpg" },
  { base: "06_Hostina/K&Mwedd_host409.jpg" },
  { base: "06_Hostina/K&Mwedd_host410.jpg" },
  { base: "06_Hostina/K&Mwedd_host411.jpg" },
  { base: "06_Hostina/K&Mwedd_host412.jpg" },
  { base: "06_Hostina/K&Mwedd_host413.jpg" },
  { base: "06_Hostina/K&Mwedd_host414.jpg" },
  { base: "06_Hostina/K&Mwedd_host415.jpg" },
  { base: "06_Hostina/K&Mwedd_host416.jpg" },
  { base: "06_Hostina/K&Mwedd_host417.jpg" },
  { base: "06_Hostina/K&Mwedd_host418.jpg" },
  { base: "06_Hostina/K&Mwedd_host419.jpg" },
  { base: "06_Hostina/K&Mwedd_host420.jpg" },
  { base: "06_Hostina/K&Mwedd_host421.jpg" },
  { base: "06_Hostina/K&Mwedd_host422.jpg" },
  { base: "06_Hostina/K&Mwedd_host423.jpg" },
  { base: "06_Hostina/K&Mwedd_host424.jpg" },
  { base: "06_Hostina/K&Mwedd_host425.jpg" },
  { base: "06_Hostina/K&Mwedd_host426.jpg" },
  { base: "06_Hostina/K&Mwedd_host427.jpg" },
  { base: "06_Hostina/K&Mwedd_host428.jpg" },
  { base: "06_Hostina/K&Mwedd_host429.jpg" },
  { base: "06_Hostina/K&Mwedd_host430.jpg" },
  { base: "06_Hostina/K&Mwedd_host431.jpg" },
  { base: "06_Hostina/K&Mwedd_host432.jpg" },
  { base: "06_Hostina/K&Mwedd_host433.jpg" },
  { base: "06_Hostina/K&Mwedd_host434.jpg" },
  { base: "06_Hostina/K&Mwedd_host435.jpg" },
  { base: "06_Hostina/K&Mwedd_host436.jpg" },
  { base: "06_Hostina/K&Mwedd_host437.jpg" },
  { base: "06_Hostina/K&Mwedd_host438.jpg" },
  { base: "06_Hostina/K&Mwedd_host439.jpg" },
  { base: "06_Hostina/K&Mwedd_host440.jpg" },
  { base: "06_Hostina/K&Mwedd_host441.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host442.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host443.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host444.jpg", portait: true },
  { base: "06_Hostina/K&Mwedd_host445.jpg" },
  { base: "06_Hostina/K&Mwedd_host446.jpg" },
  { base: "06_Hostina/K&Mwedd_host447.jpg" },
  { base: "06_Hostina/K&Mwedd_host448.jpg" },
  { base: "06_Hostina/K&Mwedd_host449.jpg" },
  { base: "06_Hostina/K&Mwedd_host450.jpg" },
  { base: "06_Hostina/K&Mwedd_host451.jpg" },
  { base: "06_Hostina/K&Mwedd_host452.jpg" },
  { base: "06_Hostina/K&Mwedd_host453.jpg" },
  { base: "06_Hostina/K&Mwedd_host454.jpg" },
  { base: "06_Hostina/K&Mwedd_host455.jpg" },
  { base: "06_Hostina/K&Mwedd_host456.jpg" },
  { base: "06_Hostina/K&Mwedd_host457.jpg" },
  { base: "06_Hostina/K&Mwedd_host458.jpg" },
  { base: "06_Hostina/K&Mwedd_host459.jpg" },
  { base: "06_Hostina/K&Mwedd_host460.jpg" },
  { base: "06_Hostina/K&Mwedd_host461.jpg" },
  { base: "06_Hostina/K&Mwedd_host462.jpg" },
  { base: "06_Hostina/K&Mwedd_host463.jpg" },
  { base: "06_Hostina/K&Mwedd_host464.jpg" },
  { base: "06_Hostina/K&Mwedd_host465.jpg" },
  { base: "06_Hostina/K&Mwedd_host466.jpg" },
  { base: "06_Hostina/K&Mwedd_host467.jpg" },
  { base: "06_Hostina/K&Mwedd_host468.jpg" },
  { base: "06_Hostina/K&Mwedd_host469.jpg" },
  { base: "06_Hostina/K&Mwedd_host470.jpg" },
  { base: "06_Hostina/K&Mwedd_host471.jpg" },
  { base: "06_Hostina/K&Mwedd_host472.jpg" },
  { base: "06_Hostina/K&Mwedd_host473.jpg" },
  { base: "06_Hostina/K&Mwedd_host474.jpg" },
  { base: "06_Hostina/K&Mwedd_host475.jpg" },
  { base: "06_Hostina/K&Mwedd_host476.jpg" },
  { base: "06_Hostina/K&Mwedd_host477.jpg" },
  { base: "06_Hostina/K&Mwedd_host478.jpg" },
  { base: "06_Hostina/K&Mwedd_host479.jpg" },
  { base: "06_Hostina/K&Mwedd_host480.jpg" },
  { base: "06_Hostina/K&Mwedd_host481.jpg" },
  { base: "06_Hostina/K&Mwedd_host482.jpg" },
  { base: "06_Hostina/K&Mwedd_host483.jpg" },
  { base: "06_Hostina/K&Mwedd_host484.jpg" },
  { base: "06_Hostina/K&Mwedd_host485.jpg" },
  { base: "06_Hostina/K&Mwedd_host486.jpg" },
  { base: "06_Hostina/K&Mwedd_host487.jpg" },
  { base: "06_Hostina/K&Mwedd_host488.jpg" },
  { base: "06_Hostina/K&Mwedd_host489.jpg" },
  { base: "06_Hostina/K&Mwedd_host490.jpg" },
  { base: "06_Hostina/K&Mwedd_host491.jpg" },
  { base: "06_Hostina/K&Mwedd_host492.jpg" },
  { base: "06_Hostina/K&Mwedd_host493.jpg" },
  { base: "06_Hostina/K&Mwedd_host494.jpg" },
].map((picture) => prepareImages(picture));

export const imageBatch7 = [
  { base: "07_Fotobudka/20200828_200557_100_IMG_0007.JPG" },
  { base: "07_Fotobudka/20200828_200700_726_IMG_0008.JPG" },
  { base: "07_Fotobudka/20200828_200736_337_IMG_0009.JPG" },
  { base: "07_Fotobudka/20200828_201250_289_IMG_0010.JPG" },
  { base: "07_Fotobudka/20200828_201821_861_IMG_0011.JPG" },
  { base: "07_Fotobudka/20200828_201859_370_IMG_0012.JPG" },
  { base: "07_Fotobudka/20200828_202202_625_IMG_0013.JPG" },
  { base: "07_Fotobudka/20200828_202231_760_IMG_0014.JPG" },
  { base: "07_Fotobudka/20200828_202305_410_IMG_0015.JPG" },
  { base: "07_Fotobudka/20200828_202506_773_IMG_0016.JPG" },
  { base: "07_Fotobudka/20200828_202528_222_IMG_0017.JPG" },
  { base: "07_Fotobudka/20200828_202702_873_IMG_0018.JPG" },
  { base: "07_Fotobudka/20200828_202812_176_IMG_0019.JPG" },
  { base: "07_Fotobudka/20200828_202922_483_IMG_0020.JPG" },
  { base: "07_Fotobudka/20200828_203026_738_IMG_0021.JPG" },
  { base: "07_Fotobudka/20200828_203051_017_IMG_0022.JPG" },
  { base: "07_Fotobudka/20200828_203131_664_IMG_0023.JPG" },
  { base: "07_Fotobudka/20200828_203151_207_IMG_0024.JPG" },
  { base: "07_Fotobudka/20200828_203410_414_IMG_0025.JPG" },
  { base: "07_Fotobudka/20200828_203502_519_IMG_0026.JPG" },
  { base: "07_Fotobudka/20200828_203555_830_IMG_0027.JPG" },
  { base: "07_Fotobudka/20200828_203706_444_IMG_0028.JPG" },
  { base: "07_Fotobudka/20200828_203725_078_IMG_0029.JPG" },
  { base: "07_Fotobudka/20200828_203743_394_IMG_0030.JPG" },
  { base: "07_Fotobudka/20200828_203825_333_IMG_0031.JPG" },
  { base: "07_Fotobudka/20200828_203843_952_IMG_0032.JPG" },
  { base: "07_Fotobudka/20200828_203929_348_IMG_0033.JPG" },
  { base: "07_Fotobudka/20200828_204026_739_IMG_0034.JPG" },
  { base: "07_Fotobudka/20200828_204045_151_IMG_0035.JPG" },
  { base: "07_Fotobudka/20200828_204117_312_IMG_0036.JPG" },
  { base: "07_Fotobudka/20200828_204248_277_IMG_0037.JPG" },
  { base: "07_Fotobudka/20200828_204307_621_IMG_0038.JPG" },
  { base: "07_Fotobudka/20200828_204402_946_IMG_0039.JPG" },
  { base: "07_Fotobudka/20200828_204439_573_IMG_0040.JPG" },
  { base: "07_Fotobudka/20200828_204532_711_IMG_0041.JPG" },
  { base: "07_Fotobudka/20200828_204618_688_IMG_0042.JPG" },
  { base: "07_Fotobudka/20200828_204659_001_IMG_0043.JPG" },
  { base: "07_Fotobudka/20200828_204836_122_IMG_0044.JPG" },
  { base: "07_Fotobudka/20200828_205034_684_IMG_0045.JPG" },
  { base: "07_Fotobudka/20200828_205116_699_IMG_0046.JPG" },
  { base: "07_Fotobudka/20200828_205209_718_IMG_0047.JPG" },
  { base: "07_Fotobudka/20200828_205237_531_IMG_0048.JPG" },
  { base: "07_Fotobudka/20200828_205336_022_IMG_0049.JPG" },
  { base: "07_Fotobudka/20200828_205432_797_IMG_0050.JPG" },
  { base: "07_Fotobudka/20200828_205459_793_IMG_0051.JPG" },
  { base: "07_Fotobudka/20200828_205519_022_IMG_0052.JPG" },
  { base: "07_Fotobudka/20200828_205543_503_IMG_0053.JPG" },
  { base: "07_Fotobudka/20200828_205609_797_IMG_0054.JPG" },
  { base: "07_Fotobudka/20200828_205639_999_IMG_0055.JPG" },
  { base: "07_Fotobudka/20200828_205733_495_IMG_0056.JPG" },
  { base: "07_Fotobudka/20200828_205800_590_IMG_0057.JPG" },
  { base: "07_Fotobudka/20200828_205820_176_IMG_0058.JPG" },
  { base: "07_Fotobudka/20200828_210103_507_IMG_0059.JPG" },
  { base: "07_Fotobudka/20200828_210121_962_IMG_0060.JPG" },
  { base: "07_Fotobudka/20200828_210204_866_IMG_0061.JPG" },
  { base: "07_Fotobudka/20200828_210230_704_IMG_0062.JPG" },
  { base: "07_Fotobudka/20200828_210251_382_IMG_0063.JPG" },
  { base: "07_Fotobudka/20200828_210321_386_IMG_0064.JPG" },
  { base: "07_Fotobudka/20200828_210358_864_IMG_0065.JPG" },
  { base: "07_Fotobudka/20200828_210419_659_IMG_0066.JPG" },
  { base: "07_Fotobudka/20200828_210444_820_IMG_0067.JPG" },
  { base: "07_Fotobudka/20200828_210503_492_IMG_0068.JPG" },
  { base: "07_Fotobudka/20200828_210532_587_IMG_0069.JPG" },
  { base: "07_Fotobudka/20200828_210559_689_IMG_0070.JPG" },
  { base: "07_Fotobudka/20200828_210851_610_IMG_0071.JPG" },
  { base: "07_Fotobudka/20200828_211223_051_IMG_0072.JPG" },
  { base: "07_Fotobudka/20200828_211249_613_IMG_0073.JPG" },
  { base: "07_Fotobudka/20200828_211430_391_IMG_0074.JPG" },
  { base: "07_Fotobudka/20200828_211549_983_IMG_0075.JPG" },
  { base: "07_Fotobudka/20200828_211703_790_IMG_0076.JPG" },
  { base: "07_Fotobudka/20200828_211723_245_IMG_0077.JPG" },
  { base: "07_Fotobudka/20200828_211820_737_IMG_0078.JPG" },
  { base: "07_Fotobudka/20200828_212855_133_IMG_0079.JPG" },
  { base: "07_Fotobudka/20200828_212927_445_IMG_0080.JPG" },
  { base: "07_Fotobudka/20200828_213038_812_IMG_0081.JPG" },
  { base: "07_Fotobudka/20200828_213621_227_IMG_0082.JPG" },
  { base: "07_Fotobudka/20200828_213642_213_IMG_0083.JPG" },
  { base: "07_Fotobudka/20200828_213752_228_IMG_0084.JPG" },
  { base: "07_Fotobudka/20200828_213829_062_IMG_0085.JPG" },
  { base: "07_Fotobudka/20200828_213857_910_IMG_0086.JPG" },
  { base: "07_Fotobudka/20200828_213934_524_IMG_0087.JPG" },
  { base: "07_Fotobudka/20200828_213953_351_IMG_0088.JPG" },
  { base: "07_Fotobudka/20200828_214050_490_IMG_0089.JPG" },
  { base: "07_Fotobudka/20200828_215840_996_IMG_0090.JPG" },
  { base: "07_Fotobudka/20200828_215900_305_IMG_0091.JPG" },
  { base: "07_Fotobudka/20200828_215942_283_IMG_0092.JPG" },
  { base: "07_Fotobudka/20200828_220623_129_IMG_0093.JPG" },
  { base: "07_Fotobudka/20200828_221626_754_IMG_0094.JPG" },
  { base: "07_Fotobudka/20200828_221659_732_IMG_0095.JPG" },
  { base: "07_Fotobudka/20200828_221824_273_IMG_0096.JPG" },
  { base: "07_Fotobudka/20200828_222212_227_IMG_0097.JPG" },
  { base: "07_Fotobudka/20200828_222251_067_IMG_0098.JPG" },
  { base: "07_Fotobudka/20200828_222418_593_IMG_0099.JPG" },
  { base: "07_Fotobudka/20200828_222449_322_IMG_0100.JPG" },
  { base: "07_Fotobudka/20200828_222741_781_IMG_0101.JPG" },
  { base: "07_Fotobudka/20200828_222809_727_IMG_0102.JPG" },
  { base: "07_Fotobudka/20200828_223000_398_IMG_0103.JPG" },
  { base: "07_Fotobudka/20200828_223024_180_IMG_0104.JPG" },
  { base: "07_Fotobudka/20200828_223111_348_IMG_0105.JPG" },
  { base: "07_Fotobudka/20200828_223137_000_IMG_0106.JPG" },
  { base: "07_Fotobudka/20200828_223233_490_IMG_0107.JPG" },
  { base: "07_Fotobudka/20200828_223253_988_IMG_0108.JPG" },
  { base: "07_Fotobudka/20200828_223646_284_IMG_0109.JPG" },
  { base: "07_Fotobudka/20200828_223709_702_IMG_0110.JPG" },
  { base: "07_Fotobudka/20200828_223735_905_IMG_0111.JPG" },
  { base: "07_Fotobudka/20200828_223759_841_IMG_0112.JPG" },
  { base: "07_Fotobudka/20200828_223848_664_IMG_0113.JPG" },
  { base: "07_Fotobudka/20200828_223909_381_IMG_0114.JPG" },
].map((picture) => prepareImages(picture));
