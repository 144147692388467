import React from "react";
import styled from "styled-components";
import moment from "moment/moment";
import { device } from "./Device";

const HorizontalSection = styled.hr`
  width: 50%;
  border: none;
  border-top: 2px solid #000;
`;

const NumberSpan = styled.span`
  font-size: 5rem;
  line-height: 7.7rem;
  letter-spacing: 0.1em;
  margin-bottom: 1.5rem;
`;

const DaySpan = styled.span`
  text-transform: uppercase;
  font-size: 3.5rem;
  line-height: 3.8rem;
  letter-spacing: 0.5em;
  margin-top: 2.7rem;
`;

const CounterDiv = styled.div`
  width: 100%;
  height: 80%;
  display: grid;
  background-color: #feeae3;
  justify-items: center;
  align-items: center;
  align-content: center;

  @media ${device.laptop} {
    height: 100%;
    margin-bottom: 5rem;
  }
`;

const remainingDays = () => {
  let remainingDays = moment("2020-08-29").diff(moment(), "days");
  return Math.abs(remainingDays);
};

const Counter = () => {
  return (
    <CounterDiv>
      <NumberSpan>{remainingDays()}</NumberSpan>
      <HorizontalSection />
      <DaySpan>dni</DaySpan>
    </CounterDiv>
  );
};

export default Counter;
