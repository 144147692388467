import React from "react";
import styled from "styled-components";

const HeadingTitle = styled.h1`
  font-weight: normal;
  line-height: 7.6rem;
  letter-spacing: 0.03em;
  font-family: "Playlist Script";
  font-size: 5rem;
`;

const PersonSection = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  width: 100%;
`;

const PersonInfo = (props) => {
  return (
    <PersonSection>
      <HeadingTitle>{props.name}</HeadingTitle>
      <div>
        <div>{props.mobile}</div>
        <div>{props.email}</div>
      </div>
    </PersonSection>
  );
};

export default PersonInfo;
