import React from "react";
import styled from "styled-components";
import { device } from "./Device";
import ReactPlayer from "react-player/youtube";

const PrimaryTitle = styled.span`
  color: #fff;
  display: block;
  font-size: 7rem;
  line-height: 10.6rem;
  font-weight: 300;
  font-family: "Playlist Script", sans-serif;

  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  grid-gap: 30px;

  @media ${device.mobileL} {
    grid-auto-flow: row;
  }
`;

const DateTitle = styled.span`
  color: #fff;

  display: block;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: 0.5em;
  animation: moveInRight 1s ease-out;
  margin-top: 2rem;
`;

const AndLetter = styled.span`
  font-family: "Lora", serif;
  font-size: 10rem;
  color: #ffe3e2;
  margin-left: 1rem;
`;

const StyledPlayer = styled.div`
  margin-top: 3.5rem;
  width: auto;
  height: 40vh;
`;

const Header = () => {
  return (
    <header className="header">
      <div className="header__text-box">
        <h1 className="heading-primary">
          <PrimaryTitle>
            Kikuš<AndLetter>&</AndLetter>Michal
          </PrimaryTitle>
          <DateTitle>28. AUGUST 2020</DateTitle>
        </h1>
        <StyledPlayer>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=lC1BBzfswSo"
            controls
            width="100%"
            height="100%"
          />
        </StyledPlayer>
      </div>
    </header>
  );
};

export default Header;
