import React from "react";
import styled from "styled-components";
import { bubble as Menu } from "react-burger-menu";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "./img/logo.png";

const StyledHamburger = styled(GiHamburgerMenu)`
  color: white;
`;

const LogoDiv = styled.div`
  height: 30vh;
  width: 100%;
`;

const LinkDiv = styled.div`
  width: 100%;
  border-bottom: ${(props) => (props.last ? "none" : "2px solid #FFE3E2")};
`;

const MenuLink = styled(AnchorLink)`
  width: 100%;
  display: block;
  text-decoration: none;
  outline: none;
  color: #8e8d8d;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  transition: 0.5s;
  padding-left: 1rem;
  line-height: 4rem;

  &:hover {
    background-color: #ffc7c8;
    color: white;
  }
`;

const MenuLogo = styled.div`
  background: no-repeat url("${logo}");
  height: 100%;
  width: 40%;
  background-size: contain;
  background-position: left;
  margin: auto;
`;

const BurgerMenu = (props) => {
  let styles;
  if (props.showMenu) {
    styles = { bmBurgerButton: { visibility: "visible" } };
  } else {
    styles = { bmBurgerButton: { visibility: "hidden", opacity: 0 } };
  }
  return (
    <Menu
      customBurgerIcon={<StyledHamburger />}
      pageWrapId={props.pageWrapId}
      className={props.showMenu ? "menuVisible" : "menuHide"}
      outerContainerId={props.outerContainerId}
      styles={styles}
    >
      <LogoDiv>
        <MenuLogo />
      </LogoDiv>

      <LinkDiv>
        <MenuLink href="#section-gallery" offset="0px">
          Galéria
        </MenuLink>
      </LinkDiv>

      <LinkDiv>
        <MenuLink href="#section-about">Náš Príbeh</MenuLink>
      </LinkDiv>

      <LinkDiv>
        <MenuLink href="#section-contacts">Kontakt</MenuLink>
      </LinkDiv>
    </Menu>
  );
};

export default BurgerMenu;
