import React from "react";
import Gallery from "react-grid-gallery";
import styled from "styled-components";
import { device } from "../Device";
import VerticalRule from "../VerticalRule";

import {
  imageBatch1,
  imageBatch2,
  imageBatch3,
  imageBatch4,
  imageBatch5,
  imageBatch6,
  imageBatch7,
} from "./Pictures";

const GalleryWrapper = styled.div`
  display: block;
  min-height: 1px;
  overflow: auto;
  width: 100%;
`;

const GalleryHeading = styled.h1`
  font-family: "Playlist Script";
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0.03em;
  margin-bottom: 0.5rem;
  margin-top: 0.7rem;

  @media ${device.tablet} {
    font-size: 2.5rem;
  }
`;

const DownloadLink = styled.a`
  width: 20%;
  text-align: center;
  display: block;
  text-decoration: none;
  outline: none;
  color: #8e8d8d;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  transition: 0.5s;
  padding-left: 1rem;
  line-height: 4rem;
  margin-bottom: 2rem;

  &:hover {
    background-color: #ffc7c8;
    color: white;
  }
`;

const WholeGallerySection = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

const WeddingGallery = () => {
  return (
    <div>
      <div>
        <GalleryHeading>Prípravy</GalleryHeading>
        <GalleryWrapper>
          <Gallery
            images={imageBatch1}
            enableImageSelection={false}
            imageCountSeparator={" / "}
            showLightboxThumbnails
            lightboxWidth={2056}
            rowHeight={160}
          />
        </GalleryWrapper>
      </div>
      <div>
        <GalleryHeading>Portréty</GalleryHeading>
        <GalleryWrapper>
          <Gallery
            images={imageBatch2}
            enableImageSelection={false}
            imageCountSeparator={" / "}
            showLightboxThumbnails
            lightboxWidth={2056}
            rowHeight={160}
          />
        </GalleryWrapper>
      </div>
      <div>
        <GalleryHeading>Obrad</GalleryHeading>
        <GalleryWrapper>
          <Gallery
            images={imageBatch3}
            enableImageSelection={false}
            imageCountSeparator={" / "}
            showLightboxThumbnails
            lightboxWidth={2056}
            rowHeight={160}
          />
        </GalleryWrapper>
      </div>
      <div>
        <GalleryHeading>Gratulácie</GalleryHeading>
        <GalleryWrapper>
          <Gallery
            images={imageBatch4}
            enableImageSelection={false}
            imageCountSeparator={" / "}
            showLightboxThumbnails
            lightboxWidth={2056}
            rowHeight={160}
          />
        </GalleryWrapper>
      </div>
      <div>
        <GalleryHeading>Skupinky</GalleryHeading>
        <GalleryWrapper>
          <Gallery
            images={imageBatch5}
            enableImageSelection={false}
            imageCountSeparator={" / "}
            showLightboxThumbnails
            lightboxWidth={2056}
            rowHeight={160}
          />
        </GalleryWrapper>
      </div>
      <div>
        <GalleryHeading>Hostina</GalleryHeading>
        <GalleryWrapper>
          <Gallery
            images={imageBatch6}
            enableImageSelection={false}
            imageCountSeparator={" / "}
            showLightboxThumbnails
            lightboxWidth={2056}
            rowHeight={160}
          />
        </GalleryWrapper>
      </div>

      <div>
        <GalleryHeading>Fotobúdka</GalleryHeading>
        <GalleryWrapper>
          <Gallery
            images={imageBatch7}
            enableImageSelection={false}
            imageCountSeparator={" / "}
            showLightboxThumbnails
            lightboxWidth={2056}
            rowHeight={160}
          />
        </GalleryWrapper>
      </div>

      <VerticalRule style={{ width: "100%", marginTop: "5rem" }} />

      <WholeGallerySection>
        <h2 className="heading__secondary">Celá galéria</h2>
        <div style={{ display: "grid", justifyItems: "center" }}>
          <DownloadLink href="https://s3.eu-central-1.amazonaws.com/kristinkaamichal.sk/28082020_kikus_michal.zip">
            Stiahnuť tu
          </DownloadLink>
        </div>
        <p>
          Ak by sa Vám nejaká fotka páčila a radi by ste si ju vytlačili,
          neváhajte nás kontaktovať a my Vám ju pošleme vo fajnovej kvalite na
          tlač.
        </p>
      </WholeGallerySection>
    </div>
  );
};

export default WeddingGallery;
