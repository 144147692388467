import React, { useState } from "react";
import "./styles/main.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";
import VerticalRule from "./VerticalRule";
import Section from "./Section";
import Footer from "./styles/Footer";
import PersonInfo from "./PersonInfo";
import Header from "./Header";
import Counter from "./Counter";
import styled from "styled-components";
import { FaArrowCircleUp } from "react-icons/fa";
import LogoImage from "./LogoImage";
import BurgerMenu from "./BurgerMenu";
import ReactGA from "react-ga";
import WeddingGallery from "./WeddingGallery/WeddingGallery";

ReactGA.initialize("UA-169913034-1");
ReactGA.pageview(window.location.pathname);

const ScrollTop = styled.div`
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
  transition: visibility 0.5s, opacity 0.5s linear;
  opacity: ${(props) => (props.show ? 0.6 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};

  &:hover {
    opacity: 1;
  }
`;

const ScrollIcon = styled(FaArrowCircleUp)`
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  color: #ffc7c8;
`;

function App() {
  const [showScroll, setShowScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100) {
      setShowScroll(true);
      setShowMenu(true);
    } else if (showScroll && window.pageYOffset <= 100) {
      setShowScroll(false);
      setShowMenu(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div id="outer-container">
      <BurgerMenu
        showMenu={showMenu}
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
      />

      <ScrollTop show={showScroll}>
        <ScrollIcon
          onClick={scrollTop}
          style={{ cursor: "pointer", height: "5rem", width: "5rem" }}
        />
      </ScrollTop>

      <nav className="top-menu">
        <LogoImage />
        <AnchorLink href="#section-gallery" offset="50px">
          Galéria
        </AnchorLink>
        <AnchorLink href="#section-about">Náš Príbeh</AnchorLink>
        <AnchorLink href="#section-contacts">Kontakt</AnchorLink>
      </nav>

      <Header />

      <main id="page-wrap">
        <Section title="Už sme svoji" heading="Presne ..." klass="counter" full>
          <Counter />
        </Section>

        <Section
          title="Galéria"
          heading="SPOMIENKY NA LETO"
          klass="gallery"
        >
          <WeddingGallery />
        </Section>

        <VerticalRule />

        <section id="section-about" className="section-about">
          <div className="our-portaits"></div>
          <div className="section-about__text_part">
            <div className="heading">
              <h2 className="heading__secondary">Náš príbeh</h2>
              <h3 className="heading__tertiary">ALEBO TROCHU O NÁS</h3>
            </div>
            <div className="section-about__story">
              Prekonali sme divoké a náladové tínedžerské obdobie, zvládli sme
              vzťah na diaľku a započali sme aj naše prvé spoločné bývanie, v
              ktorom je nám mimochodom doteraz výborne. Už je to nejaký ten čas
              a našli sme si kopu spoločných záujmov. Obaja máme radi
              spoločenské hry, varenie dobrôt od výmyslu sveta, náš kurz
              spoločenských tancov a tak isto čas strávený s našou rodinou a
              kamarátmi. Radi si pochutnáme na dobrom vínku či pálenke, pozrieme
              film alebo seriál a hoci nevieme spievať, tak si spolu sem-tam
              zanôtime. Sme veľmi štastní, že sme to dotiahli až sem a tešíme sa
              na ďalšie prekvapenia nášho spoločného života.
            </div>
          </div>
        </section>

        <Section title="Kontakt" heading="Máš otázku?" klass="contacts" full>
          <div className="section-contacts__info">
            <PersonInfo
              name="Michal"
              mobile="+421 907 282 347"
              email="michalgranec@gmail.com"
            />
            <PersonInfo
              name="Kikuš"
              mobile="+421 903 864 300"
              email="kristina.granec@gmail.com"
            />
          </div>
        </Section>
      </main>
      <Footer>Navrhli a vytvorili Kikuš a Michal</Footer>
    </div>
  );
}

export default App;
